import React, { useState } from "react"
import "./style.css"
import * as shortner from "../../services/shortner"
import Spinner from "../Spinner"

const Form = ({ setUrl, url, addResult }) => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    return (
        <div className="Form">
            <div className={[`Error`, error ? "on" : ""].join(" ")}>
                <p>{error}</p>
                <button onClick={() => setError("")}>&times;</button>
            </div>
            <form
                onSubmit={(e) => {
                    setError("")
                    e.preventDefault()
                    setLoading(true)
                    shortner.shorten(url)
                        .then(data => {
                            setUrl("")
                            addResult(data)
                        })
                        .catch(err => {
                            console.error(err)
                            setError(err.message)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }}
            >
                <input placeholder="Shorten URL" required value={url} onChange={e => setUrl(e.target.value)} />
                <button type="submit">{loading ? <Spinner className="spinner button" /> : ""} Shorten</button>
            </form>
        </div>
    )
}

export default Form