import "whatwg-fetch"

import React from 'react';
import Form from '../Form';
import Header from '../Header';
import Results from '../Result';
import './style.css';
import Storage from "../../utils/persistent-storage"
import * as urlFormatter from "../../utils/format-url"

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      results: [],
      url: "",
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const results = Storage.get("results")
      if (results) {
        this.setState({ results: results })
      }
    }, 100)
  }

  render() {
    return (
      <div className="App">
        <Header />
        <main>
          <Form
            addResult={result => {
              if (!this.state.results.find(x => x.hash === result.hash)) {
                const newResults = [].concat(result, ...this.state.results)
                this.setState({ results: newResults })
                Storage.set("results", newResults)
              }
            }}
            setUrl={x => this.setState({ url: urlFormatter.format(x) })}
            url={this.state.url}
          />
          <Results values={this.state.results} />
        </main>
        <footer>
          powered by <a href="https://foodaciously.com" rel="nofollow">foodaciously</a>
        </footer>
      </div>
    )
  }
}

export default App;
