export const shorten = (long_url) => {
    return fetch(`https://fdly.link/v1/shorten`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            long_url: long_url
        })
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json()
            } else if (resp.status === 422) {
                throw new Error("I can only shorten foodaciously.com!")
            } else {
                throw new Error("Whoops! Something went wrong.")
            }
        })
}