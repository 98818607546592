import React, { useState, memo } from "react"
import "./style.css"
import * as clipboard from "../../utils/clipboard"

const Result = ({ value }) => {
    const [copied, setCopied] = useState(false)
    return (
        <li>
            <span>{value.long_url}</span>
            <div>
                <span><a href={value.short_url} target="_blank" rel="nofollow noopener noreferrer">{value.short_url}</a></span>
                <button className={copied ? "copied" : ""} onClick={() => {
                    clipboard.copy(document, value.short_url, () => {
                        setCopied(true)
                        setTimeout(() => setCopied(false), 1000)
                    })
                }}>{copied ? "copied" : "copy"}</button>
            </div>
        </li>
    )
}

const Results = memo(({ values }) => {
    console.log(values)
    if (!values.length) return null
    return (
        <ul className="Result">
            {values.map(r => (
                <Result key={r.hash} value={r} />
            ))}
        </ul>
    )
})

export default Results